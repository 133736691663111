<template>
  <el-container class="layout-container-demo">
    <el-header>
      <div class="header-container">
        <div class="logo">
          <img :src="conf.logo" height="50" alt="">
          <span class="logo-txt">盛景和</span>
        </div>
        <div class="menus">
          <div class="menu-item" :class="{'menu-item-active': current == 'Index'}" @click="selectMenu('Index')">首页</div>
          <!-- <div class="menu-item" :class="{'menu-item-active': current == 'Schemes'}" @click="selectMenu('Schemes')">方案</div> -->
          <div class="menu-item" :class="{'menu-item-active': current == 'Videos'}" @click="selectMenu('Videos')">视频</div>
          <div class="menu-item" :class="{'menu-item-active': current == 'Discover'}" @click="selectMenu('Discover')">发现</div>
          <div class="menu-item" v-if="user.identity == 'partner'" :class="{'menu-item-active': current == 'Manage'}" @click="selectMenu('Manage')">管理中心</div>
          <!-- <div class="menu-item" :class="{'menu-item-active': current == 'Manage'}" @click="selectMenu('Manage')">管理中心</div> -->
          <el-button v-show="!isLogin" class="login-btn" type="default" @click="login">登录</el-button>
          <div class="user" v-show="isLogin">
            <span>
              <span>欢迎, </span>
              <span style="color: #3aa20f;">{{ user.name }}</span>
            </span>
            <el-button class="logout-btn" size="small" @click="logout">退出</el-button>
          </div>
        </div>
      </div>
    </el-header>
    <el-scrollbar>
      <el-main>
        <div class="container">
          <router-view></router-view>
          <el-footer></el-footer>
        </div>
      </el-main>
    </el-scrollbar>
  </el-container>
</template>

<script setup>
import { ref, onMounted } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from 'vuex'
import conf from '@/conf'
import { ElMessageBox } from 'element-plus'

const current = ref('Index')
const router = useRouter()
const store = useStore()
const user = store.state.user
const isLogin = !!store.state.token

const selectMenu = menu => {
  current.value = menu
  router.push({ name: menu })
}

const login = () => {
  router.push({ name: 'Login' })
}

const logout = () => {
  ElMessageBox.confirm('确定要退出当前用户吗？', '提示', {
      confirmButtonText: '确定',
      cancelButtonText: '取消',
      type: 'warning'
    }).then(() => {
      store.commit('logout')
      location.href = '/'
    }).catch(() => {
    })
}

onMounted(() => {
  current.value = router.currentRoute.value.name
})

</script>

<style lang="less" scoped>
.layout-container-demo {
  height: 100vh;
  .el-header {
    box-shadow: 0 2px 10px #ccc;
    height: 80px;
    display: flex;
    justify-content: center;
    z-index: 9;
    .header-container {
      width: 1200px;
      height: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .logo {
        display: flex;
        align-items: center;
        .logo-txt {
          font-size: 20px;
          margin-left: 10px;
          font-weight: bold;
          color: #009944;
        }
      }
      .menus {
        display: flex;
        align-items: center;
        height: 100%;
        .user {
          font-size: 12px;
          padding-left: 20px;
          .logout-btn {
            margin-left: 20px;
          }
        }
        .menu-item {
          padding: 0 40px;
          height: 100%;
          display: flex;
          align-items: center;
          cursor: pointer;
        }
        .menu-item-active {
          color: #3aa20f;
          position: relative;
          &::after {
            position: absolute;
            content: '';
            left: 0;
            right: 0;
            bottom: 0;
            height: 3px;
            display: inline-block;
            background-color: #3aa20f;
          }
        }
        .login-btn {
          margin-left: 20px;
        }
      }
    }
  }
  .el-main {
    display: flex;
    justify-content: center;
    .container {
      width: 1600px;
    }
  }
}
</style>
